.slick-slider{box-sizing:border-box;-webkit-user-select:none;-moz-user-select:none;-ms-user-select:none;user-select:none;-webkit-touch-callout:none;-khtml-user-select:none;-ms-touch-action:pan-y;touch-action:pan-y;-webkit-tap-highlight-color:transparent}.slick-list,.slick-slider{position:relative;display:block}.slick-list{overflow:hidden;margin:0;padding:0}.slick-list:focus{outline:0}.slick-list.dragging{cursor:pointer;cursor:hand}.slick-slider .slick-list,.slick-slider .slick-track{-webkit-transform:translateZ(0);-ms-transform:translateZ(0);transform:translateZ(0)}.slick-track{position:relative;top:0;left:0;display:block;margin-left:auto;margin-right:auto}.slick-track:after,.slick-track:before{display:table;content:""}.slick-track:after{clear:both}.slick-loading .slick-track{visibility:hidden}.slick-slide{display:none;float:left;height:100%;min-height:1px}[dir=rtl] .slick-slide{float:right}.slick-slide img{display:block}.slick-slide.slick-loading img{display:none}.slick-slide.dragging img{pointer-events:none}.slick-initialized .slick-slide{display:block}.slick-loading .slick-slide{visibility:hidden}.slick-vertical .slick-slide{display:block;height:auto;border:1px solid transparent}.slick-arrow.slick-hidden{display:none}

.cursor-pointer {
    cursor: pointer;
}

.color-white {
    color: var(--white) !important;
}

.color-green {
    color: var(--green) !important;
}

.text-yellow {
    color: var(--yellow) !important;
}

.bg-yellow {
    background: var(--yellow) !important;
}

.bg-red {
    background: var(--red) !important;
}

.scrollToTop {
    color: var(--green) !important;
}

.box-shadow-simple {
    box-shadow: 0 16px 32px 0 rgb(7 14 29 / 6%) !important;
}

.border-green {
    border: 1px solid green;
}

.customDatePicker {
    display: grid;
    grid-template-columns: repeat(7, 14.2% [col-start]);
    row-gap: 5px;
}

.customDatePickerItem {
    justify-self: center;
}

.customDatePickerItemMultiple .active.customDatePickerItem:hover {
    background: var(--green);
    color: var(--smoke-color) !important;
}

.customDatePickerItemMultiple .customDatePickerItem:hover {
    background: var(--white);
    color: var(--green) !important;
}

.icon-btn-disabled {
    width: 60px;
    height: 60px;
    line-height: 62px;
    display: inline-block;
    text-align: center;
    border-radius: 50%;
    /*background-color: var(--light);*/
    background-image: linear-gradient(145deg, #ffffff 25%, #e8e8e8 25%, #e8e8e8 50%, #ffffff 50%, #ffffff 75%, #e8e8e8 75%, #e8e8e8 100%);
    background-size: 20.92px 14.65px;
}

@media only screen and (max-width: 768px) {
    .btn-icon-extension {
        width: 30px !important;
    }
}

.hover-green:hover {
    color: var(--green) !important;
}

@media only screen and (max-width: 768px) {
    .calculator-left-icon {
        position: relative;
        bottom: 20px;
        right: 70px;
    }
}

@media only screen and (min-width: 769px) {
    .calculator-left-icon {
        position: relative;
        bottom: 20px;
        right: 100px;
    }
}

.icon-hover-effect:hover i {
    transform: scale(1.1) translateY(-3px);
    transition-duration: 0.1s;
    font-weight: bold;
}
.text-hover-effect:hover span {
    transition-duration: 0.1s;
    font-weight: bold;
    font-size: 18px;
}

@media only screen and (max-width: 768px) {
    .mobile-left-100 {
        right: 100px;
    }
}




h1.calculation {
    position: relative;
    margin: 0 auto;
    top: 5vh;
    text-align: center;
    font-size: 4vh;
    color: #333;
    opacity: 0.75;
    animation: pulse 2.5s linear infinite;
}

@media only screen and (max-width: 768px) {
    #calc-cooking {
        position: relative;
        margin: 0 auto;
        top: 0;
        right: 15vh;
        width: 75vh;
        height: 50vh;
        overflow: hidden;
    }
}
@media only screen and (min-width: 769px) {
    #calc-cooking {
        position: relative;
        margin: 0 auto;
        top: 0;
        right: 5vh;
        width: 75vh;
        height: 50vh;
        overflow: hidden;
    }
}

#calc-cooking #calc-area {
    position: absolute;
    bottom: 0;
    right: 0;
    width: 50%;
    height: 50%;
    background-color: transparent;
    transform-origin: 15% 60%;
    animation: flip 2.1s ease-in-out infinite;
}

#calc-cooking #calc-area #calc-sides {
    position: absolute;
    width: 100%;
    height: 100%;
    transform-origin: 15% 60%;
    animation: switchSide 2.1s ease-in-out infinite;
}

#calc-cooking #calc-area #calc-sides #calc-handle {
    position: absolute;
    bottom: 18%;
    right: 80%;
    width: 35%;
    height: 20%;
    background-color: transparent;
    border-top: 1vh solid saddlebrown;
    border-left: 1vh solid transparent;
    border-radius: 100%;
    transform: rotate(20deg) rotateX(0deg) scale(1.3, 0.9);
}
#calc-cooking #calc-area #calc-sides #calc-pan {
    position: absolute;
    bottom: 20%;
    right: 30%;
    width: 50%;
    height: 8%;
    background-color: #333;
    border-radius: 0 0 1.4em 1.4em;
    transform-origin: -15% 0;
}

#calc-cooking #calc-area #calc-pancake {
    position: absolute;
    top: 24%;
    width: 100%;
    height: 100%;
    transform: rotateX(85deg);
    animation: jump 2.1s ease-in-out infinite;
}

#calc-cooking #calc-area #calc-pancake #calc-pastry {
    position: absolute;
    bottom: 26%;
    right: 37%;
    width: 40%;
    height: 45%;
    background: #f7d788;
    box-shadow: 0 0 3px 0 #333;
    border-radius: 100%;
    transform-origin: -20% 0;
    animation: fly 2.1s ease-in-out infinite, fry 6.3s ease-in-out infinite;
}

#calc-cooking .calc-bubble {
    position: absolute;
    border-radius: 100%;
    box-shadow: 0 0 0.25vh #4d4d4d;
    opacity: 0;
}

#calc-cooking .calc-bubble:nth-child(1) {
    margin-top: 2.5vh;
    left: 58%;
    width: 2.5vh;
    height: 2.5vh;
    background-color: #454545;
    animation: calc-bubble 2s cubic-bezier(0.53, 0.16, 0.39, 0.96) infinite;
}

#calc-cooking .calc-bubble:nth-child(2) {
    margin-top: 3vh;
    left: 52%;
    width: 2vh;
    height: 2vh;
    background-color: #3d3d3d;
    animation: calc-bubble 2s ease-in-out 0.35s infinite;
}

#calc-cooking .calc-bubble:nth-child(3) {
    margin-top: 1.8vh;
    left: 50%;
    width: 1.5vh;
    height: 1.5vh;
    background-color: #333;
    animation: calc-bubble 1.5s cubic-bezier(0.53, 0.16, 0.39, 0.96) 0.55s infinite;
}

#calc-cooking .calc-bubble:nth-child(4) {
    margin-top: 2.7vh;
    left: 56%;
    width: 1.2vh;
    height: 1.2vh;
    background-color: #2b2b2b;
    animation: calc-bubble 1.8s cubic-bezier(0.53, 0.16, 0.39, 0.96) 0.55s infinite;
}

#calc-cooking .calc-bubble:nth-child(5) {
    margin-top: 2.7vh;
    left: 63%;
    width: 1.1vh;
    height: 1.1vh;
    background-color: #242424;
    animation: calc-bubble 1.6s ease-in-out 1s infinite;
}


/*@keyframes pulse {*/
/*    0% {*/
/*        transform: scale(1, 1);*/
/*        opacity: 0.25;*/
/*    }*/
/*    50% {*/
/*        transform: scale(1.2, 1);*/
/*        opacity: 1;*/
/*    }*/
/*    100% {*/
/*        transform: scale(1, 1);*/
/*        opacity: 0.25;*/
/*    }*/
/*}*/

@keyframes flip {
    0% {
        transform: rotate(0deg);
    }

    5% {
        transform: rotate(-27deg);
    }

    30%, 50% {
        transform: rotate(0deg);
    }

    55% {
        transform: rotate(27deg);
    }

    83.3% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(0deg);
    }
}

@keyframes switchSide {
    0% {
        transform: rotateY(0deg) ;
    }

    50% {
        transform: rotateY(180deg);
    }
    100% {
        transform: rotateY(0deg);
    }
}

@keyframes jump {
    0% {
        top: 24%;
        transform: rotateX(85deg);
    }
    25% {
        top: 10%;
        transform: rotateX(0deg);
    }
    50% {
        top: 30%;
        transform: rotateX(85deg);
    }
    75% {
        transform: rotateX(0deg);
    }
    100% {
        transform: rotateX(85deg);
    }
}

@keyframes fly {
    0% {
        bottom: 26%;
        transform: rotate(0deg);
    }
    10% {
        bottom: 40%;
    }
    50% {
        bottom: 26%;
        transform: rotate(-190deg);
    }
    80% {
        bottom: 40%;
    }
    100% {
        bottom: 26%;
        transform: rotate(0deg);
    }
}

@keyframes fry {
    0% {
        background: #EFE6D0;
    }
    10% {
        background:#F7D788;
    }
    50% {
        background: #C6B68D;
    }
    80% {
        background: #877C61;
    }
    100% {
        background: #877C61;
    }
}

@keyframes calc-bubble {
    0% {
        transform: scale(0.15, 0.15);
        top: 80%;
        opacity: 0;
    }
    50% {
        transform: scale(1.1, 1.1);
        opacity: 1;
    }
    100% {
        transform: scale(0.33, 0.33);
        top: 60%;
        opacity: 0;
    }
}

.custom-toggle {
    font-size: 26px;
    font-weight: bold;
    margin: 0 20px;
}
.custom-toggle.active {
    /*background: #009432;*/
    color: #009432;
    text-decoration: underline;
    text-decoration-color: #009432;
}

.custom-price {
    display: flex;
    justify-content: flex-end;
}

.custom-primary-button:hover {
    color: #009432 !important;
    /*-webkit-box-shadow: 3px 3px 13px 2px rgba(0,148,50,0.4);*/
    /*box-shadow: 3px 3px 13px 2px rgba(0,148,50,0.4);*/
    /*-webkit-box-shadow: 0px 0px 13px 12px rgba(0,148,50,0.4);*/
    /*box-shadow: 0px 0px 13px 12px rgba(0,148,50,0.4);*/
    -webkit-box-shadow: 0 0 13px 8px rgba(0,148,50,0.4);
    box-shadow: 0 0 13px 8px rgba(0,148,50,0.4);
}

.home-image-bottom {
    border: 1px solid #009432;
    transition: transform .2s;
    cursor: not-allowed;
    -webkit-box-shadow: 3px 3px 6px -3px #009432;
}

.home-image {
    border: 1px solid #009432;
    box-shadow: 5px 5px 12px -3px #009432;
    -webkit-box-shadow: 5px 5px 12px -3px #009432;
    transition: transform .2s;
}

.home-image:hover {
    cursor: not-allowed;
}

.home-image::after{
    content: '\A';
    position: absolute;
    width: 100%; height:100%;
    top:0; left:0;
    background:rgba(0,0,0,0.6);
    opacity: 0.1;
}

.button-shadow {
    border: 1px solid #009432;
    box-shadow: 5px 5px 12px -3px #009432;
    -webkit-box-shadow: 5px 5px 12px -3px #009432;
    transition: transform .2s;
}

.small-home-image {
    text-align: right;
    position: relative;
    margin-top: -100px;
    padding-right: 110px;
    padding-left: 90px;
}

.small-home-image:hover {
    z-index: 1;
}

.border-exp-box {
    border: 1px solid #009432;
    box-shadow: 5px 5px 12px -3px #009432;
}

.border-exp-box:before {
    border: 2px dashed #009432;
}

.mask-style1 {
    box-shadow: 3px 3px 6px -2px #009432;
}

.mask-style1:hover {
    /*border-inline: 1px solid #009432;*/
    box-shadow: 3px 3px 6px -2px #009432;
    color: #009432 !important;
}

.calculator-panel {
    border-radius: 8px;
    -webkit-box-shadow: 3px 3px 3px 3px rgba(0,0,0,0.27);
    box-shadow: 3px 3px 3px 3px rgba(0,0,0,0.27);
}

label {
    text-transform: none !important;
}

input::placeholder {
    text-transform: none !important;
}

.first-letter-capital::first-letter{
    text-transform: uppercase;
}

.hidden {
    visibility: hidden;
}

.text-hover-parent:hover .text-hover-child{
    color: #009432;
    transition: color 0.7s ease;
}

.checkboxLabelFix::before {
    top: 4px !important;
}

.vs-mobile-menu ul li a {
    font-size: 1.2rem !important;
}

.recipe-tag {
    padding: 3px 10px;
    background-color: #009432;

    margin-bottom: 5px;
    color: var(--white);
    cursor: pointer;
}

.recipe-tag:first-child {
    margin-left: 0;
}

.fridge-ingredient-container {
    padding: 5px;
    margin: -5px;
    display: inline-flex;
    gap: 10px;
    flex-wrap: wrap;
    max-height: 250px;
    overflow: auto;

}

.fridge-ingredient {
    padding: 3px 10px;
    background-color: var(--white);

    margin-bottom: 5px;
    color: #009432;
    cursor: pointer;

    -webkit-box-shadow: 2px 2px 4px 2px rgba(0,148,50,0.4);
    -moz-box-shadow: 2px 2px 4px 2px rgba(0,148,50,0.4);
    box-shadow: 2px 2px 4px 2px rgba(0,148,50,0.4);
}

.fridge-ingredient-selected {
    padding: 3px 10px;
    background-color: #009432;

    margin-bottom: 5px;
    color: var(--white);
    cursor: pointer;
}

.fridge-ingredient:hover {
    /*color: var(--white);*/
    /*background-color: #66FF99;*/
    -webkit-box-shadow: 2px 2px 4px 2px #098748FF;
    -moz-box-shadow: 2px 2px 4px 2px #098748FF;
    box-shadow: 2px 2px 4px 2px #098748FF;
}

.fridge-recipe-add-ingredients {
    background-color: var(--white);
    border: 1px solid #009432;
    color: #009432;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}

.fridge-recipe-add-ingredients:hover {
    background-color: #009432;
    color: var(--white);
}

.video-responsive {
    overflow: hidden;
    padding-bottom: 56.25%;
    position: relative;
    height: 0;
}

.video-responsive iframe {
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
    position: absolute;
}

/*Menu section*/
.mobile-menu-wrapper {
    position: fixed;
    top: 80px;
    left: 0;
    right: 0;
    z-index: 3000;
    display: flex;
    flex-direction: row;
    background-color: transparent;
    transition: background-color 0.3s;
}

.mobile-menu-left {
    height: 100%;
    background: var(--white);
    transition: width 0.5s 0.3s;
}

.mobile-menu-left ul {
    margin-left: 0;
    padding-left: 0;
    list-style: none;
}

.mobile-menu-left ul li {
    font-size: 30px;
    padding: 15px 10px;
    color: #332233;
}

.mobile-menu-overlay {
    height: 100%;
}

.menu-style3 > ul > li > a {
    padding: 0 !important;
    margin: 28px 0;
}

.custom-hover-text-effect-1:hover {
    font-size: 18px;
    font-weight: 500;
}

.card-header-filter .rotate-icon {
    margin-left: 10px;
}
.card-header-filter:not(.collapsed) .rotate-icon {
    transform: rotate(180deg);
}

.radius-12 {
    border-radius: 12px;
}

.radius-12-before:before {
    border-radius: 12px;
}

.social-links.links-has-border a {
    line-height: 40px !important;
}

.social-links.links-has-border i {
    line-height: 40px !important;
}

/* Think pink */
.recipe-ribbon {
    position: absolute;
    top: 20px;
    left: 20px;
    width: 80px;
    height: 80px;
    font-size: 80px;
    color: deeppink;
    z-index: 33;
}
.think-pink {
    display: flex;
    justify-content: center;
}
.think-pink-text {
    color: deeppink;
}
a.think-pink-text {
    color: deeppink !important;
    text-decoration: underline;
    text-decoration-color: deeppink !important;
}
a.think-pink-text:hover {
    color: #009432 !important;
    text-decoration: underline;
    text-decoration-color: #009432 !important;
}
.think-pink .custom-toggle {

}
.think-pink a.custom-toggle i {
    margin-right: 8px;
}
.think-pink .custom-toggle.active {
    /*background: -webkit-linear-gradient(135deg, #009432, pink 65%);*/
    /*-webkit-background-clip: text;*/
    /*-webkit-text-fill-color: transparent;*/
    color: deeppink;
    text-decoration-color: deeppink !important;
    text-decoration-style: double;
}
.custom-toggle path {
    fill: blue;
}

.think-pink-content {

}

#pinkRecipeContent {
    font-size: 21px;
    text-align: justify !important;
    min-height: 700px;
    margin-bottom: 32px;
}

#pinkRecipeContent h2 {
    text-align: center;
}

@media screen and (max-width: 1440px) {
    .recipe-ribbon {
        width: 60px;
        height: 60px;
        font-size: 60px;
    }
}
@media screen and (max-width: 920px) {
    .root {
        overflow: hidden !important;
    }

    #pinkRecipeContent {
        font-size: 18px;
    }

    .think-pink-content {
        /*padding-left: 16px;*/
        /*padding-right: 16px;*/
    }
    .think-pink .recipe-ribbon {
        width: 40px;
        height: 40px;
        font-size: 40px;
    }

    .think-pink .col-md-6 {
        text-align: left !important;
    }

    .think-pink ul {
        width: calc(100vw - 16px);
        display: flex !important;
        margin-left: -2px;
        gap: 6px;
        margin-bottom: 8px;
    }

    .think-pink ul li {
        width: calc(33% - 6px);
        border: 1px solid #333;
        border-radius: 12px;
        margin: 0 !important;
    }

    .think-pink ul li span {
        /*height: 53px;*/
    }

    .think-pink ul li:has(> .active) {
        background-color: deeppink;
        color: white !important;
    }

    .think-pink .custom-toggle {
        height: 120px;
        font-size: 14px;
    }
    .think-pink a.custom-toggle  {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
    .think-pink a.custom-toggle i {
        font-size: 36px;
        margin-bottom: 8px;
        position: relative;
        left: 6px;
    }
    .think-pink .custom-toggle.active {
        /*background: -webkit-linear-gradient(135deg, #009432, pink 65%);*/
        /*-webkit-background-clip: text;*/
        /*-webkit-text-fill-color: transparent;*/
        color: white;
        text-decoration: none;
    }
}

/*.mobile-menu .show .sub-menu {*/
/*    background-color: #d1d9d1;*/
/*}*/

/* Home 2 */

.home .menu-item,
.home .menu-item a{
    color: #009432 !important;
}

.home .header-logo{
    display: none;
}

#home-section {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    min-height: 100px;
    padding-top: 100px;
    background: white;
}

.curve {
    position: absolute;
    height: 200px;
    width: 100%;
    bottom: 0;
    text-align: center;
}

@media screen and (max-width: 920px) {
    .curve {
        height: 50px;
    }
}

.curve::before {
    content: '';
    display: block;
    position: absolute;
    border-radius: 100% 50%;
    width: 55%;
    height: 100%;
    /*background-color: white;*/
    background-color: #009432;
    transform: translate(85%, 60%);
}

.curve::after {
    content: '';
    display: block;
    position: absolute;
    /*background-color: #009432;*/
    background-color: white;
    border-radius: 100% 50%;
    width: 55%;
    height: 100%;
    transform: translate(-4%, 40%);
    z-index: 1;
}

/* Blog */
.vs-blog-layout1 .vs-blog::before {
    border-radius: 12px;
}

.vs-box1 .vs-box-icon {
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1) !important;
}

.frame {
    height: 300px;
    background: transparent no-repeat center;
    background-size: cover;
}

.blog-container-style {
    text-align: justify;
    font-size: 21px;
}

.blog-quote {
    font-size: 28px;
}

@media screen and (max-width: 920px) {
    .frame {
        height: 150px;;
    }

    .blog-container-style {
        text-align: left;
        font-size: 18px;
    }

    .blog-quote {
        font-size: 21px;
    }
}

@keyframes loading-skeleton {
    from {
        opacity: .4;
    }
    to {
        opacity: 1;
    }
}
.loading-skeleton {
    pointer-events: none;
    animation: loading-skeleton 1s infinite alternate;

    color: transparent;
    appearance: none;
    -webkit-appearance: none;
    background-color: #eee;
    border-color: #eee;
}
div .loading-skeleton {
    filter: grayscale(100) contrast(0%) brightness(1.8);
}

